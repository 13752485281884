






























































































































































































































































































































































.my-baby-wrapper {
  // .page-wrapper {
  //   width: 1300px;
  // }
  // overflow: hidden;
  padding: 0.7rem 0.15rem 0.5rem 2rem;
  padding: 0.7rem 0.3rem 0.3rem 2rem;
  .add-baby {
    position: absolute;
    z-index: 10;
    cursor: pointer;

    .icontianjia {
      color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-30%, -50%);
    }
    &.add-baby-center {
      position: relative;
      text-align: center;
      background: #fff;
      width: 258px;
      height: 328px;
      border: 5px solid #e3facd;
      top: 50%;
      left: 50%;
      // transform: translate(-50%, -50%);
      transform: translate(-83%, -56%);
      border-radius: 30px;
      .add-baby-inner {
        width: 84px;
        height: 84px;
        // background: #ffce75;
        background: url("../../assets/new_ui/add_baby.png") no-repeat;
        // border-radius: 12px;
        // border: 4px solid #ffffff;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -70%);

        // .icontianjia {
        //   font-size: 30px;
        // }

        &:hover {
          // width: 92px;
          // height: 98px;
          // background: #f3bb55;
          // opacity: 0.8;
          background: url("../../assets/new_ui/add_baby_hover.png") no-repeat;
          background-size: 100% 100%;
          // position: relative;
          // top: 50%;
          // left: 50%;
          // transform: translate(-48%, -65%);
        }
      }
      .p-add {
        height: 16px;
        font-size: 16px;
        line-height: 16px;
        color: #46851b;
        margin-top: 120px;
      }
    }
    &.add-baby-right-top {
      width: 0.7rem;
      height: 0.7rem;
      // background: #ffce75;
      background: url("../../assets/new_ui/add_baby.png") no-repeat;
      background-size: 100% 100%;

      // border-radius: 12px;
      // border: 4px solid #ffffff;
      bottom: 0.75rem;
      right: 0.66rem;

      &:hover {
        // background: #f3bb55;
        // opacity: 0.8;
        background: url("../../assets/new_ui/add_baby_hover.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  ::-webkit-scrollbar {
    display: block !important;
  }
  .baby-list {
    width: 100%;
    height: 100%;
    overflow: auto;
    // padding: 0.82rem 2.14rem;
    // padding-right: 1rem;
    padding-top: 0.3rem;
    padding-left: 0.1rem;
    margin-bottom: 0;
    padding-right: 0;
    // overflow: hidden;
    &:hover {
      // overflow: scroll;
    }

    &.baby-list-1 {
      // padding-top: 1.2rem;
      // padding-left: 2.3rem;
      padding-top: 1.28rem;
      padding-left: 1.65rem;
    }
    &.baby-list-2 {
      // padding-top: 100px;
      // padding-left: 235px;
      // padding: 50px;
      // margin: auto;
      // text-align: center;
      // padding: 100px;
      // padding: 1rem 2.1rem 0 2.1rem;
      margin-top: 0.1rem;
      .baby-item {
        // margin-right: 38px;
        margin-right: 0.5rem;
      }
    }
    &.baby-list-n {
      // margin-top: 40px;
      // padding-top: 40px;
      // padding-left: 90px;
      // margin-bottom: 40px;
      height: calc(100% - 26px);
      .baby-item {
        margin-right: 0.5rem;
      }
    }
    .baby-item {
      // width: 218px;
      // height: 216px;
      // padding: 80px 20px 0;
      padding: 1.31rem 0px 0;
      // background: #fff9ee;
      border-radius: 30px;
      position: relative;
      display: inline-block;
      margin-bottom: 0.58rem;
      &.baby-item-1 {
        .baby-name,
        .baby-org {
          color: #54a3b4;
        }
        .baby-age,
        .baby-class,
        .baby-edit {
          color: #7abcc9;
        }
      }
      &.baby-item-2 {
        .baby-name,
        .baby-org {
          color: #ff4940;
        }
        .baby-age,
        .baby-class,
        .baby-edit {
          color: #f98f89;
        }
      }
      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
      .baby-item-box {
        // width: 190px;
        // height: 140px;
        width: 2.58rem;
        height: 1.74rem;
        text-align: center;
        background: url("../../assets/new_ui/baby_box.png") no-repeat 100% 100%;
        position: relative;
        background-size: 100%;
        padding: 0 0.2rem;
        padding-top: 28px;
        overflow: hidden;
      }
      .img-top-bg {
        width: 2.358rem;
        height: 2.19rem;
        border-radius: 20px 20px 0 0;
        position: absolute;
        top: -23px;
        left: 8px;
        left: 0.11rem;
      }
      .baby-edit {
        width: 0.62rem;
        height: 0.64rem;
        display: none;
        position: absolute;
        right: 0.03rem;
        bottom: 0.06rem;

        // right: 3px;
        // bottom: 6px;
        font-size: 20px;
        z-index: 10;

        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .baby-name {
        // font-size: 16px;
        font-size: 18px;
        height: 20px;
        // line-height: 16px;
        margin-top: 10px;

        font-size: 0.18rem;
        height: 0.2rem;
        margin-top: 0.1rem;
      }
      .baby-age {
        // font-size: 14px;
        font-size: 16px;
        height: 14px;
        // line-height: 14px;
        margin-top: 0.1rem;

        font-size: 0.16rem;
        height: 0.14rem;
        margin-top: 0.1rem;
      }
      .baby-org {
        font-size: 16px;
        height: 16px;
        // line-height: 16px;
        margin-top: 0.2rem;

        font-size: 0.16rem;
        height: 0.16rem;
        margin-top: 0.1rem;
      }
      .baby-class {
        font-size: 0.16rem;
        height: 0.2rem;
        margin-top: 0.1rem;
        position: relative;
        z-index: 5;
      }
      &:hover {
        .baby-edit {
          display: inline-block;
        }
      }
    }
  }
}

.add-baby-dialog {
  .el-form {
    .form-item-studentBirthday {
      .el-date-editor {
        width: 280px;
        .el-input__prefix {
          display: none;
        }
        .el-input__suffix {
          display: inline-block;
          width: 20px;
          height: 18px;
          background-image: url("../../assets/new_ui/icon-calendar.png");
          background-repeat: no-repeat;
          background-size: 20px 18px;
          position: absolute;
          right: 0.12rem;
          top: 12px;
          .el-input__suffix-inner {
            display: none;
          }
        }
      }
    }
  }
  .bottom-btns {
    padding-left: 100px;
    margin-bottom: 20px;
    .el-button {
      width: 280px;
    }
  }
  .self-el-form {
    margin-left: 5px;
  }
}
