.my-baby-wrapper {
  padding: 0.7rem 0.15rem 0.5rem 2rem;
  padding: 0.7rem 0.3rem 0.3rem 2rem;
}
.my-baby-wrapper .add-baby {
  position: absolute;
  z-index: 10;
  cursor: pointer;
}
.my-baby-wrapper .add-baby .icontianjia {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);
}
.my-baby-wrapper .add-baby.add-baby-center {
  position: relative;
  text-align: center;
  background: #fff;
  width: 258px;
  height: 328px;
  border: 5px solid #e3facd;
  top: 50%;
  left: 50%;
  transform: translate(-83%, -56%);
  border-radius: 30px;
}
.my-baby-wrapper .add-baby.add-baby-center .add-baby-inner {
  width: 84px;
  height: 84px;
  background: url("../../assets/new_ui/add_baby.png") no-repeat;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-51%, -70%);
}
.my-baby-wrapper .add-baby.add-baby-center .add-baby-inner:hover {
  background: url("../../assets/new_ui/add_baby_hover.png") no-repeat;
  background-size: 100% 100%;
}
.my-baby-wrapper .add-baby.add-baby-center .p-add {
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  color: #46851b;
  margin-top: 120px;
}
.my-baby-wrapper .add-baby.add-baby-right-top {
  width: 0.7rem;
  height: 0.7rem;
  background: url("../../assets/new_ui/add_baby.png") no-repeat;
  background-size: 100% 100%;
  bottom: 0.75rem;
  right: 0.66rem;
}
.my-baby-wrapper .add-baby.add-baby-right-top:hover {
  background: url("../../assets/new_ui/add_baby_hover.png") no-repeat;
  background-size: 100% 100%;
}
.my-baby-wrapper ::-webkit-scrollbar {
  display: block !important;
}
.my-baby-wrapper .baby-list {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 0.3rem;
  padding-left: 0.1rem;
  margin-bottom: 0;
  padding-right: 0;
}
.my-baby-wrapper .baby-list.baby-list-1 {
  padding-top: 1.28rem;
  padding-left: 1.65rem;
}
.my-baby-wrapper .baby-list.baby-list-2 {
  margin-top: 0.1rem;
}
.my-baby-wrapper .baby-list.baby-list-2 .baby-item {
  margin-right: 0.5rem;
}
.my-baby-wrapper .baby-list.baby-list-n {
  height: calc(100% - 26px);
}
.my-baby-wrapper .baby-list.baby-list-n .baby-item {
  margin-right: 0.5rem;
}
.my-baby-wrapper .baby-list .baby-item {
  padding: 1.31rem 0px 0;
  border-radius: 30px;
  position: relative;
  display: inline-block;
  margin-bottom: 0.58rem;
}
.my-baby-wrapper .baby-list .baby-item.baby-item-1 .baby-name,
.my-baby-wrapper .baby-list .baby-item.baby-item-1 .baby-org {
  color: #54a3b4;
}
.my-baby-wrapper .baby-list .baby-item.baby-item-1 .baby-age,
.my-baby-wrapper .baby-list .baby-item.baby-item-1 .baby-class,
.my-baby-wrapper .baby-list .baby-item.baby-item-1 .baby-edit {
  color: #7abcc9;
}
.my-baby-wrapper .baby-list .baby-item.baby-item-2 .baby-name,
.my-baby-wrapper .baby-list .baby-item.baby-item-2 .baby-org {
  color: #ff4940;
}
.my-baby-wrapper .baby-list .baby-item.baby-item-2 .baby-age,
.my-baby-wrapper .baby-list .baby-item.baby-item-2 .baby-class,
.my-baby-wrapper .baby-list .baby-item.baby-item-2 .baby-edit {
  color: #f98f89;
}
.my-baby-wrapper .baby-list .baby-item:nth-of-type(2n + 2) {
  margin-right: 0;
}
.my-baby-wrapper .baby-list .baby-item .baby-item-box {
  width: 2.58rem;
  height: 1.74rem;
  text-align: center;
  background: url("../../assets/new_ui/baby_box.png") no-repeat 100% 100%;
  position: relative;
  background-size: 100%;
  padding: 0 0.2rem;
  padding-top: 28px;
  overflow: hidden;
}
.my-baby-wrapper .baby-list .baby-item .img-top-bg {
  width: 2.358rem;
  height: 2.19rem;
  border-radius: 20px 20px 0 0;
  position: absolute;
  top: -23px;
  left: 8px;
  left: 0.11rem;
}
.my-baby-wrapper .baby-list .baby-item .baby-edit {
  width: 0.62rem;
  height: 0.64rem;
  display: none;
  position: absolute;
  right: 0.03rem;
  bottom: 0.06rem;
  font-size: 20px;
  z-index: 10;
  cursor: pointer;
}
.my-baby-wrapper .baby-list .baby-item .baby-edit:hover {
  opacity: 0.8;
}
.my-baby-wrapper .baby-list .baby-item .baby-name {
  font-size: 18px;
  height: 20px;
  margin-top: 10px;
  font-size: 0.18rem;
  height: 0.2rem;
  margin-top: 0.1rem;
}
.my-baby-wrapper .baby-list .baby-item .baby-age {
  font-size: 16px;
  height: 14px;
  font-size: 0.16rem;
  height: 0.14rem;
  margin-top: 0.1rem;
}
.my-baby-wrapper .baby-list .baby-item .baby-org {
  font-size: 16px;
  height: 16px;
  margin-top: 0.2rem;
  font-size: 0.16rem;
  height: 0.16rem;
  margin-top: 0.1rem;
}
.my-baby-wrapper .baby-list .baby-item .baby-class {
  font-size: 0.16rem;
  height: 0.2rem;
  margin-top: 0.1rem;
  position: relative;
  z-index: 5;
}
.my-baby-wrapper .baby-list .baby-item:hover .baby-edit {
  display: inline-block;
}
.add-baby-dialog .el-form .form-item-studentBirthday .el-date-editor {
  width: 280px;
}
.add-baby-dialog .el-form .form-item-studentBirthday .el-date-editor .el-input__prefix {
  display: none;
}
.add-baby-dialog .el-form .form-item-studentBirthday .el-date-editor .el-input__suffix {
  display: inline-block;
  width: 20px;
  height: 18px;
  background-image: url("../../assets/new_ui/icon-calendar.png");
  background-repeat: no-repeat;
  background-size: 20px 18px;
  position: absolute;
  right: 0.12rem;
  top: 12px;
}
.add-baby-dialog .el-form .form-item-studentBirthday .el-date-editor .el-input__suffix .el-input__suffix-inner {
  display: none;
}
.add-baby-dialog .bottom-btns {
  padding-left: 100px;
  margin-bottom: 20px;
}
.add-baby-dialog .bottom-btns .el-button {
  width: 280px;
}
.add-baby-dialog .self-el-form {
  margin-left: 5px;
}
