

























































































































































































































.history-wrapper {
  display: inline-block;
  width: 100%;
  // min-height: calc(100vh - 270px) !important;
  padding: 0.35rem;

  display: flex;
  justify-content: center;
  .desc-box {
    // top: 0.2rem;
    .img-desc {
      right: 0.2rem;
      top: 0.2rem;
    }
  }
  .desc-tip {
    width: 2.32rem !important;
    height: 1.74rem !important;
    top: 0.1rem !important;
    left: 0.1rem !important;
    // overflow: auto !important;
    padding: 0.15rem 0.14rem !important;

    .img-close {
      right: 0.15rem !important;
      top: 0.12rem !important;
    }
  }
  .desc-tip::-webkit-scrollbar {
    display: none !important;
  }
  .resource-content {
    position: relative;
    padding-left: 0.4rem !important;
    padding-top: 0.05rem !important;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 0 auto;

    .scroll-loading {
      width: 100%;
      height: 50px;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: -50px;
      .img-scroll-loading {
        width: 50px;
        height: 50px;
      }
    }
    .desc-content {
      font-size: 0.12rem !important;
      width: 105% !important;
      height: 96% !important;
      line-height: 0.17rem !important;
      padding-top: 0.02rem;
      margin-top: 0.2rem !important;
    }
  }
  ::-webkit-scrollbar {
    display: block !important;
  }
  // ::-webkit-scrollbar {
  //   display: none !important;
  // }

  .no-data-box {
    // margin-top: 185px;
  }
  .no-data-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-no-data {
      // width: 100px;
      // flex: 1;
      margin-top: 0px;
      margin-right: 50px;
      width: 190px;
      height: 160px;
    }
  }

  .resource-item-inner {
    .el-image__inner {
      width: 100% !important;
      height: 100% !important;
    }
    .resource-count {
      // width: 74px;
      // height: 20px;
      // line-height: 20px;
      // font-size: 12px;

      width: 0.74rem;
      height: 0.2rem;
      line-height: 0.2rem;
      font-size: 0.12rem;
      bottom: 0.5rem;
    }
  }
}
.resource-name {
  font-size: 0.15rem !important;
}
