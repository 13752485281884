.basic-info-wrapper .self-el-form {
  padding-top: 1.2rem;
  padding-left: 2.45rem;
  padding-right: 2.63rem;
}
.basic-info-wrapper .self-el-form .el-form-item__label {
  font-size: 0.14rem;
  height: 0.4rem;
  line-height: 0.4rem;
}
.basic-info-wrapper .self-el-form .el-input__icon {
  font-size: 0.14rem;
  height: 0.4rem;
  line-height: 0.4rem;
  margin-right: 0.05rem;
}
.basic-info-wrapper .self-el-form .el-form-item {
  margin-top: 0.3rem;
}
.basic-info-wrapper .self-el-form .el-form-item .el-form-item__content {
  line-height: 0;
}
.basic-info-wrapper .self-el-form .el-form-item .el-form-item__content .el-input {
  width: 3.68rem;
  height: 0.44rem;
  font-size: 0.14rem;
}
.basic-info-wrapper .self-el-form .el-form-item .el-form-item__content .el-input .el-input__inner {
  height: 0.4rem;
}
.basic-info-wrapper .el-select .el-input .el-input__suffix {
  height: 0.4rem;
  line-height: 0.4rem;
}
.basic-info-wrapper .bottom-btns {
  width: 100%;
  height: 0.44rem;
}
.basic-info-wrapper .bottom-btns .el-button {
  width: 3.38rem;
  height: 0.44rem;
  margin-left: 3.61rem;
  margin-right: 2.87rem;
  font-size: 0.16rem;
  line-height: 0.44rem !important;
}
.basic-info-wrapper .self-el-form {
  padding-bottom: 0;
}
.el-input.is-disabled .el-input__inner {
  background: #c2eda6 !important;
}
